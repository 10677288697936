import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ServiceDetailsContent from "../components/ServiceDetails/ServiceDetailsContent"
import WebDevelopmentImage from "../assets/images/services/web-development.png"

const WebDevelopment = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Web Development"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Web Development"
      />
      <ServiceDetailsContent
        aboutService="Making software programs are not an easy job, but our Software developers are very experienced and familiar with all modern tools"
        technologies={[
          "HTML5",
          "CSS3",
          "JavaScript",
          "Reactjs",
          "Redux",
          "Typescript",
          "Firebase",
          "Nodejs",
          "MongoDB",
        ]}
        detailImage={WebDevelopmentImage}
      />
      {/* <RelatedServices /> */}
      <Footer />
    </Layout>
  )
}

export default WebDevelopment
